import { StaseResiden, StaseResidenAPI } from "./staseResiden";
import ListAPI from "@/apps/core/api/list";
import ViewListModel from "@/apps/core/models/viewListModel";

export class StaseResidenListAPI extends ListAPI {
  constructor() {
    super(StaseResidenAPI.modelPath);
  }
}

export default class StaseResidenListVM extends ViewListModel {
  constructor() {
    super(new StaseResidenListAPI());
  }

  getInstance(json: Record<string, any>) {
    const staseResiden = new StaseResiden();
    staseResiden.loadData(json);
    return staseResiden;
  }
}
