






























































import StaseResidenListVM from "@/apps/stase/models/staseResidenList";
import useInfiniteScrollList from "@/apps/core/modules/useInfiniteScrollList";
import { defineComponent } from "@vue/composition-api";
import { isAdmin } from "@/apps/accounts/modules/store";
import StaseResidenVM, { StaseResiden } from "../models/staseResiden";
import { DialogProgrammatic as Dialog } from "buefy";

export default defineComponent({
  name: "ResidenTable",
  props: {
    stase: null,
  },
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
  },
  setup(props) {
    const listVM = new StaseResidenListVM();
    listVM.filterMap = { stase: props.stase.id };
    const composition = useInfiniteScrollList(listVM, false);
    composition.onPageChange();

    const confirmResidenDelete = (staseResiden: StaseResiden) => {
      Dialog.confirm({
        title: "Menghapus Data",
        message: "Anda yakin akan <b>menghapus</b> residen ini?",
        confirmText: "Hapus Residen",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
            const staseResVM = new StaseResidenVM();
            if (staseResiden.id === null) return;
            await staseResVM.api.delete(staseResiden.id);
            const idx = listVM.list.indexOf(staseResiden);
            listVM.list.splice(idx, 1);
        },
      });
    };
    return {
      // Data
      isAdmin,

      // Composition
      ...composition,

      // Method
      confirmResidenDelete,
    };
  },
});
